import styled from "styled-components";

export const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;

  & .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > * {
      flex: auto;
    }

    & > img {
      width: 32px;
      flex: none;
      height: 32px;
      margin: 0 10px;
    }

    & .qty {
      display: flex;
      flex-direction: column;

      & .money {
        font-weight: bold;
        font-size: 40px;
        color: #afc8ff;
      }
    }
  }
`;

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;

  & .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid rgba(164, 180, 206, 0.4);
  }

  & .details {
    & .title {
      display: flex;
      flex-direction: row;
      border-bottom: 0;
      align-items: center;
      height: 30px;
      // justify-content: start;

      & > div {
        display: flex;
        justify-content: center;

        &.month {
          height: 100%;
        }
      }
    }

    & .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid rgba(164, 180, 206, 0.4);
      height: 45px;
      justify-content: space-between;

      & > div {
        display: flex;
        justify-content: center;
      }

      & .month {
        display: flex;
        justify-content: flex-start;
        font-size: 22px;
      }

      & img,
      & svg {
        cursor: pointer;
      }
    }

    & .month {
      width: 40%;
    }

    & .download {
      width: 30%;
      font-size: 12px;
    }
    & .preview {
      width: 30%;
      font-size: 12px;
    }
  }
`;

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;

  & .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid rgba(164, 180, 206, 0.4);
  }

  & .details {
    overflow-y: auto;
    max-height: 520px;

    & .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid rgba(164, 180, 206, 0.4);
      height: 45px;

      & .qty {
        display: flex;
        justify-content: center;
      }
    }

    & .section {
      width: 25%;
      font-size: 13px;
    }

    & .section-2 {
      width: 15px;
      font-size: 13px;
    }

    & .date {
      width: 10%;
    }

    & .details_title {
      width: 40%;
    }
    & .description {
      width: 40%;
      font-size: 12px;
    }

    & .qty {
      width: 10%;
    }
  }

  & .footer {
    display: flex;
    justify-content: flex-end;
    height: 50px;
    & .qty {
      display: flex;
      align-items: center;
      font-weight: bold;

      & span {
        margin-left: 10px;
        font-size: 32px;
        color: #afc8ff;
      }
    }
  }
`;
