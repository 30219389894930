import React from "react";
import {
  Container,
  MainHeader,
  MainContainer,
  LeftColumns,
  RightColumns,
} from "./style";
import Button from "../../shared/controls/button";
import PaymentsPurchase from "./dashboard/purchase";
import PaymentsAccount from "./dashboard/account";
import PaymentsHistory from "./dashboard/history";
import { connect } from "react-redux";
import { getEntityItems, partialEntity, readEntities, readEntity } from "../../redux/api";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import { SERVER } from "../../redux/api/api-urls";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import GenericDialog from "../../shared/dialog/generic-modal";

const FIRST_YEAR = 2021;
const TODAY = new Date();

class PaymentsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credits: 10560,
      selectedYear: FIRST_YEAR,
      verification_status: null,
      errorMsg: ''
    };
  }

  componentDidMount() {
    this.props.getCompany({
      company_id: this.props.companyId
    },{
      onSuccess: (r) => {
        this.setState({verification_status: r.verification_status});
      }
    });
    this.props.loadWalletInfo({
      company_id: this.props.companyId
    });
    this.props.loadPlansInfo({
      company_id: this.props.companyId
    });
    this.props.loadMovements({
      company_id: this.props.companyId
    });
    this.props.loadSubscriptionCatalog();
    this.props.getCoupons({
      company_id: this.props.companyId
    });
  }

  formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let splitArray = formetedNumber.split(".");
    if (splitArray.length > 1) {
      formetedNumber = splitArray[0];
    }
    return formetedNumber;
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  getUrl = (year, month, type) => {
    return `${SERVER}/api/v2/companies/${this.props.companyId}/subscriptions/?view=statement&month=${month}&year=${year}&action=download_${type}`;
  };

  getStatement = (year, month, type) => {
    Axios({
      url: this.getUrl(year, month, type),
      method: "GET",
      responseType: "blob",
      headers: this.getToken(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Edo_Cuenta_".concat(year, "_", month, ".", type === "xls" ? "xlsx" : type));
      // link.setAttribute("download", null);
      document.body.appendChild(link);
      link.click();
    });
  }

  onChange = (e) => {
    // debugger;
    this.setState({[e.target.name]: e.target.value})
  }

  buildHistoryMovements = () => {
    
    let movements = (this.props.movements || []).map( item => {
      return {
        date: moment(item.created).format('DD MMM'),
        title: item.movement_type_description,
        status: `Estatus: ${item.order_status_description}`,
        order:`Pedido #${item.id}`,
        transaction: `${item.quantity} orbibucks`,
        qty: item.quantity,
        id: item.id,
        order_status: item.order_status
      };
    })

    return movements;
  }

  getData = () => {
    let bills = [
      {
        month: "Enero",
        bill: () => {this.getStatement(this.state.selectedYear, '01', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '01', 'xls')},
      },
      {
        month: "Febrero",
        bill: () => {this.getStatement(this.state.selectedYear, '02', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '02', 'xls')},
      },
      {
        month: "Marzo",
        bill: () => {this.getStatement(this.state.selectedYear, '03', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '03', 'xls')},
      },
      {
        month: "Abril",
        bill: () => {this.getStatement(this.state.selectedYear, '04', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '04', 'xls')},
      },
      {
        month: "Mayo",
        bill: () => {this.getStatement(this.state.selectedYear, '05', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '05', 'xls')},
      },
      {
        month: "Junio",
        bill: () => {this.getStatement(this.state.selectedYear, '06', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '06', 'xls')},
      },
      {
        month: "Julio",
        bill: () => {this.getStatement(this.state.selectedYear, '07', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '07', 'xls')},
      },
      {
        month: "Agosto",
        bill: () => {this.getStatement(this.state.selectedYear, '08', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '08', 'xls')},
      },
      {
        month: "Septiembre",
        bill: () => {this.getStatement(this.state.selectedYear, '09', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '09', 'xls')},
      },
      {
        month: "Octubre",
        bill: () => {this.getStatement(this.state.selectedYear, '10', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '10', 'xls')},
      },
      {
        month: "Noviembre",
        bill: () => {this.getStatement(this.state.selectedYear, '11', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '11', 'xls')},
      },
      {
        month: "Diciembre",
        bill: () => {this.getStatement(this.state.selectedYear, '12', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '12', 'xls')},
      },
    ];
    // debugger;
    if(this.state.selectedYear === TODAY.getFullYear()){
      bills = bills.slice(0, (TODAY.getMonth() + 1));
    }

    let years = [];
    let year = FIRST_YEAR;
    while(year <= TODAY.getFullYear()){
      years.push(year++);
    }
    return {
      purchase: {
        // credits: this.formatNumber(this.state.credits),
        credits: (this.props.walletInfo[0] || {balance: '-'}).balance
      },
      account: {
        years,
        selectedYear: this.state.selectedYear,
        onChange: this.onChange,
        bills,
      },
      history: {
        movements: this.formatNumber(this.props.movements.length),
        total: this.formatNumber(0),
        details: this.buildHistoryMovements(),
      },
    };
  };

  getErrorMessageCompanyVerification = () => {
    let error = '';
    
    switch(this.state.verification_status) {
      case(null):
        error = '<b>Verifica tu información faltante</b>';
        break;
      case(0):
        error = '<b>La verificación de tu empresa esta en estatus no verificada, verifica tu información en el perfil de empresa</b>';
        break;
      case(1):
        error = 'La verificación de tu empresa esta en estatus pendiente';
        break;
      case(2):
        error = 'La verificación de tu empresa fue rechazada, verifica tu información en el perfil de empresa';
        break;
    }

    return error;
  }

  render() {
    return (
      <Container>
        <MainHeader>
          <div className="left">
            <div className="title">
              <div>Suscripción:</div>
              <span>{ this.props.planInfo !== null ? this.props.planInfo.description : ''}</span>
            </div>
          </div>
          <div className="right">
          
          </div>
        </MainHeader>
        <MainContainer>
          <div className="left">
            <LeftColumns>
              <div className="panel ">
                <PaymentsPurchase 
                  coupons={this.props.coupons}
                  errorCompanyVerification={this.getErrorMessageCompanyVerification()}
                  data={this.getData().purchase}
                  openBuyOrbibucksAction={() => {
                    this.props.history.push(`/company/payments/buy`)
                  }}
                  coupons={this.props.coupons}
                />
              </div>
              <div className="panel">
                <PaymentsAccount data={this.getData().account} />
              </div>
            </LeftColumns>
          </div>
          <div className="right">
            <RightColumns>
              <div className="panel title-panel">
                <div className="info-title">
                  <div>{"PUBLICA / PPV"}</div>
                  <span>activa desde {"abril 2020"}</span>
                  <div>SUSCRIPCIÓN ACTUAL</div>
                  {
                    this.props.isCargoOwner ? 
                    <>
                      <Button
                        disabled={this.getErrorMessageCompanyVerification() !== ''}
                        dataTip={this.getErrorMessageCompanyVerification()}
                        dataFor="subscription-tooltip-error"
                        text={ (this.props.planInfo && this.props.planInfo.id === 2) ? "Cambiar subscripción" : "Ver suscripción" }
                        type="primary"
                        onClick={() => {
                          this.props.history.push(`/company/payments/subscription`)
                        }}
                      /> 
                      <ReactTooltip
                        id={"subscription-tooltip-error"}
                        place="bottom"
                        type="light"
                        effect="solid"
                        html={true}
                        event="mouseover"
                        eventOff="mouseleave"
                      />
                    </>
                    : ''
                  }
                </div>
              </div>
              <div className="panel">
                <PaymentsHistory 
                  data={this.getData().history}
                  isLoadingCancelling={this.props.isLoadingCancelling}
                  cancelMovement={(movement_id) => {
                    this.props.cancelMovement({
                      action: 'cancel',
                      company_id: this.props.companyId,
                      movement_id
                    }, {
                      onSuccess: () => {
                        this.props.loadMovements({
                          company_id: this.props.companyId
                        });
                      },
                      onError: (e) => {
                        this.setState({
                          errorMsg: e.response?.data?.error || ["Ocurrió un error, verifica tu información"]
                        });
                      }
                    });
                  }}
                />
              </div>
            </RightColumns>
          </div>
        </MainContainer>

        <GenericDialog
          open={this.state.errorMsg !== ''}
          title="Error!"
          message={this.state.errorMsg}
          acceptAction={() => this.setState({errorMsg: ''})}
          closeAction={() => this.setState({errorMsg: ''})}
        />

      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const walletInfo = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.WALLET"); 
  const subscriptionCatalog = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.CATALOGSUBSCRIPTION") || [];
  const coupons = (getEntityItems(state, "COMPANIES.COUPON") || []).filter( item => (item?.status?.id || 0) === 2);
  const movements = getEntityItems(state, "COMPANIES.WALLETMOVEMENTS") || [];
  const isLoadingCancelling = state?.api['COMPANIES.WALLETMOVEMENTS.CANCEL']?.status?.isFetching || false;

  let planInfo = subscriptionCatalog.filter( item => {
    return (walletInfo && walletInfo.length > 0 && walletInfo[0].subscription === item.id)
  });

  return {
    companyId,
    walletInfo,
    coupons,
    subscriptionCatalog,
    planInfo: planInfo.length > 0 ? planInfo[0] : null,
    isCargoOwner: `${state.globalCatalog.session.company.type.id}` !== '1',
    movements,
    coupons,
    isLoadingCancelling
  }
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    getCompany: (params, opt) => dispatch(readEntity("COMPANIES.RETRIEVE", params, opt)),
    loadWalletInfo: (params, opt) =>
      dispatch(readEntities("COMPANIES.SUSCRIPTIONS.WALLET", params, opt)),
    loadPlansInfo: (params, opt) =>
      dispatch(readEntities("COMPANIES.SUSCRIPTIONS.PLANS", params, opt)),
    loadSubscriptionCatalog: (params, opt) =>
      dispatch(readEntities("COMPANIES.SUSCRIPTIONS.CATALOGSUBSCRIPTION", params, opt)),
    getCoupons: (params, opt) => dispatch(readEntities("COMPANIES.COUPON", params, opt)),
    loadMovements: (params, opt) => dispatch(readEntities("COMPANIES.WALLETMOVEMENTS", params, opt)),
    cancelMovement: (params, opt) => dispatch(partialEntity("COMPANIES.WALLETMOVEMENTS.CANCEL", params, opt))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsDashboard));

// export default PaymentsDashboard;
