import styled from "styled-components";

export const MainContent = styled.div`
  padding-top: 20px;
  padding-left: ${({ isSmallSize }) => (isSmallSize ? '10' : '15')}%;
  padding-right: ${({ isSmallSize }) => (isSmallSize ? '10' : '15')}%;
  overflow-x: scroll;
  height: calc(100% - 20px);
`;

export const FieldTitle = styled.div`
  color: #9ba1ac;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: ${({ isMobile }) => (isMobile ? '15' : '30')}px;
  text-align: center;

  .medium {
    font-size: ${({ isMobile }) => (isMobile ? '10' : '20')}px;
  }

  .small {
    font-size: ${({ isMobile }) => (isMobile ? '8' : '15')}px;
  }

  .underline {
    text-decoration: underline;
  }

  .link {
    font-size: ${({ isMobile }) => (isMobile ? '10' : '20')}px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const FieldSubtitle = styled.div`
  color: #9ba1ac;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: ${({ isMobile }) => (isMobile ? '15' : '25')}px;
  display: flex;
  align-items: center;
`;

export const ImgSubtitle = styled.img`
  width: ${({ isMobile }) => (isMobile ? '30' : '60')}px;
  margin-right: 10px;
`;

export const SelectorContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
`;

export const SelectorContainerHorizontal = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  flex-direction: column;
`;

export const SelectorBorder = styled.div`
  width: ${({ isMobile }) => (isMobile ? '80' : '150')}px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: ${({ isMobile }) => (isMobile ? '120' : '190')}px;
  cursor: pointer;
`;

export const SelectorBorderSmall = styled.div`
  width: ${({ isMobile }) => (isMobile ? '50' : '150')}px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: ${({ isMobile }) => (isMobile ? '120' : '190')}px;
  cursor: pointer;
`;

export const SelectorBorderHorizontalSmall = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const SelectorTitle = styled.div`
  color: #9ba1ac;
  text-align: center;
  font-size: 11px;
`;

export const SelectorTitleHorizontal = styled.div`
  color: #9ba1ac;
  text-align: left;
  font-size: 11px;
  padding-left: 8px;
`;

export const SelectorTitleMedium = styled.div`
  color: #9ba1ac;
  text-align: center;
  font-size: 14px;
`;

export const SelectorImgContainer = styled.img`
  width: 80%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const SelectorImgContainerSmall = styled.img`
  width: 15%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const BottomContainer = styled.div`
  margin-bottom: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomBlankSpace = styled.div`
  height: 50px;
  width: 100%;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgb(46, 59, 82);
  margin-top: 50px;
  margin-bottom: 20px;
`;

export const ProgressBar = styled.div`
  background-color: rgb(247, 64, 255);
  height: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: ${({ progress_width }) => (progress_width)}%;
`;

export const TextArea = styled.textarea`
  resize: none;
  border: 2px solid #EBEEF0;
  border-radius: 8px;
  padding-top: 8px;
  padding-left: 8px;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '70' : '100')}px;
`;

export const Separator = styled.div`
  height: 10px;
`;

export const HorizontalSeparator = styled.div`
  width: 10px;
`;

export const RadioItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #9ba1ac;
  font-size: 12px;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const ImgQuestion = styled.img`
  width: ${({ isMobile }) => (isMobile ? '35' : '15')}%;
`;

export const ImgQuestionMedium = styled.img`
  width: ${({ isMobile }) => (isMobile ? '30' : '10')}%;
`;

export const ImgQuestionSmall = styled.img`
  width: ${({ isMobile }) => (isMobile ? '20' : '10')}%;
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackContainer = styled.div`
  position: absolute;
  width: ${({ isMobile }) => (isMobile ? '10' : '15')}%;
  top: 0px;
  left: 0px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackButton = styled.div`
  cursor: pointer;
  color: #9ba1ac;
  font-size: ${({ isMobile }) => (isMobile ? '18' : '25')}px;
`;

export const CheckboxContainerLevel1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CheckboxContainerLevel2 = styled.div`
  width: fit-content;
`;

export const AgentContainer = styled.div`
  background-color: rgb(9, 32, 71);
  width: fit-content;
  color: #9ba1ac;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
