import React from "react";
import {
  FieldTitle,
} from "./style/view";

const getDescriptionFrom = (id, catalog, with_other) => {
  let result = (catalog || []).filter( item => `${item.id}` === `${id}` );
  return result.length > 0 ? result[0].description : with_other ? 'Otro' : '';
}

export const ResumeCO = (props) => {
  return (
    <div>
      <FieldTitle>
        <span class='medium'>{'1.- Nombre completo: '}</span>
        <br/>
        <span class='small'>{props.data['name']}</span>
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'Empresa: '}</span>
        <br/>
        <span class='small'>{props.data['company_name']}</span>
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'2.- Industria que atiendes: '}</span>

        {
          `${(props.data['industry_type'] || [])}`.split(',').map( item => {
            return(
              <React.Fragment key={`industry_type_${item}`}>
                <br/>
                <span class='small'>{`${getDescriptionFrom(item, props.industry_type_catalog)}`}</span>
              </React.Fragment>
            )
          })
        }
      </FieldTitle>


      <FieldTitle>
        <span class='medium'>{'3.- ¿Cuántos camiones completos recibes en distribución primaria inbound?(de tus proveedores a tus fábricas/cedis): '}</span>

        {
          `${(props.data['trips_received'] || [])}`.split(',').map( item => {
            return(
              <React.Fragment key={`industry_type_${item}`}>
                <br/>
                <span class='small'>{`${getDescriptionFrom(item, props.primary_distribution_t1)}`}</span>
              </React.Fragment>
            )
          })
        }
      </FieldTitle>
      
      <FieldTitle>
        <span class='medium'>{'4.- ¿Cuántos camiones completos recibes en distribución primaria outbound? (de tus fábricas a cedis): '}</span>

        {
          `${(props.data['trips_ejecuted'] || [])}`.split(',').map( item => {
            return(
              <React.Fragment key={`trips_ejecuted_${item}`}>
                <br/>
                <span class='small'>{`${getDescriptionFrom(item, props.primary_distribution_t1)}`}</span>
              </React.Fragment>
            )
          })
        }
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'5.- ¿Cuántas entregas FTL o LTL haces en tú distribución secundaria?(de tus fábricas/cedis a puntos de venta): '}</span>

        {
          `${(props.data['trips_ejecuted_t2'] || [])}`.split(',').map( item => {
            return(
              <React.Fragment key={`trips_ejecuted_t2_${item}`}>
                <br/>
                <span class='small'>{`${getDescriptionFrom(item, props.secundary_distribution_t2)}`}</span>
              </React.Fragment>
            )
          })
        }
      </FieldTitle>
      
      <FieldTitle>
        <span class='medium'>{'6.- ¿Cuántas entregas haces en distribución de última milla ? (de tus cedis/puntos de venta a tus cliente/consumidor final): '}</span>

        {
          `${(props.data['trips_ejecuted_last_mile'] || [])}`.split(',').map( item => {
            return(
              <React.Fragment key={`trips_ejecuted_last_mile_${item}`}>
                <br/>
                <span class='small'>{`${getDescriptionFrom(item, props.last_mile)}`}</span>
              </React.Fragment>
            )
          })
        }
      </FieldTitle>

      <FieldTitle><span class='medium'>
        {'7.- Cuál es tu rol en tu empresa?: '}</span>
        <br/>
        <span class='small'>{props.data['role']}</span>
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'Elige las facultades de tu rol: '}</span>

        {
          `${(props.data['faculties_in_organization'] || [])}`.split(',').map( item => {
            return(
              <React.Fragment key={`faculties_in_organization_${item}`}>
                <br/>
                <span class='small'>{`${getDescriptionFrom(item, props.faculties_in_organization)}`}</span>
              </React.Fragment>
            )
          })
        }
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'8.- Platícanos los retos y frustraciones de tu negocio: '}</span>
  
        {
          `${(props.data['productivity'] || '')}`.split(',').map( item => {
            return(
              <React.Fragment key={`productivity_${item}`}>
                <br/>
                <span class='small'>{`-${getDescriptionFrom(item, props.challenges_concerns)}`}</span>
              </React.Fragment>
            )
          })
        }
        <br/>
        {
          `${(props.data['digital_transformation'] || '')}`.split(',').map( item => {
            return(
              <React.Fragment key={`digital_transformation_${item}`}>
                <br/>
                <span class='small'>{`-${getDescriptionFrom(item, props.challenges_concerns)}`}</span>
              </React.Fragment>
            )
          })
        }

        <br/>

        {
          `${(props.data['security'] || '')}`.split(',').map( item => {
            return(
              <React.Fragment key={`security_${item}`}>
                <br/>
                <span class='small'>{`-${getDescriptionFrom(item, props.challenges_concerns)}`}</span>
              </React.Fragment>
            )
          })
        }
        <br/>

        {
          `${(props.data['distribution_and_logistics'] || '')}`.split(',').map( item => {
            return(
              <React.Fragment key={`distribution_and_logistics_${item}`}>
                <br/>
                <span class='small'>{`-${getDescriptionFrom(item, props.challenges_concerns)}`}</span>
              </React.Fragment>
            )
          })
        }
        <br/>

        {
          `${(props.data['nps'] || '')}`.split(',').map( item => {
            return(
              <React.Fragment key={`nps_${item}`}>
                <br/>
                <span class='small'>{`-${getDescriptionFrom(item, props.challenges_concerns)}`}</span>
              </React.Fragment>
            )
          })
        }
      </FieldTitle>

      {
        props.data['other_problems'] !== '' &&
        <FieldTitle>
          <span class='medium'>{'Otro:'}</span>
          <br/>
          <span class='small'>{props.data['other_problems']}</span>
        </FieldTitle>
      }

      <FieldTitle>
        <span class='medium'>{'9.- Orden de prioridad: '}</span>

        {
          `${(props.data['challenges_concerns_order'] || '')}`.split(',').map( (item, index) => {
            return(
              <React.Fragment key={`challenges_concerns_order_${item}`}>
                <br/>
                <span class='small'>{`${index + 1}.- ${getDescriptionFrom(item, props.challenges_concerns, true)}`}</span>
              </React.Fragment>
            )
          })
        }
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'10.- ¿Cómo calificarías actualmente el desempeño de la cadena de abastecimiento de tu empresa? del 0 al 10: '}</span>
        <br/>
        <span class='small'>{props.data['supply_chain_performance']}</span>
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'11.- ¿En qué nivel de adopción de transformación digital consideras que se encuentra tu empresa?: '}</span>

        {
          `${(props.data['digital_transformation_item'] || [])}`.split(',').map( item => {
            return(
              <React.Fragment key={`digital_transformation_item_${item}`}>
                <br/>
                <span class='small'>{`${getDescriptionFrom(item, props.digital_transformation)}`}</span>
              </React.Fragment>
            )
          })
        }
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'12.- ¿Están actualmente en algún proceso de adopción de tecnologías en tu cadena de abastecimiento?: '}</span>
        <span class='small'>{props.data['digital_transformation_process_or_adoption'] ? 'Si' : 'No'}</span>
      </FieldTitle>

      {
        props.data['digital_transformation_process_or_adoption'] === true &&
        <>
          <FieldTitle>
            <span class='medium'>{'¿Cuánto tiempo llevan en ese proceso?: '}</span>

            {
              `${(props.data['time_in_process'] || '')}`.split(',').map( item => {
                return(
                  <React.Fragment key={`time_in_process_${item}`}>
                    <br/>
                    <span class='small'>{`${getDescriptionFrom(item, props.time_in_process)}`}</span>
                  </React.Fragment>
                )
              })
            }

          </FieldTitle>

          <FieldTitle>
            <span class='medium'>{'¿Cuánto llevan invertido?: '}</span>

            {
              `${(props.data['budget_invested_in_your_project'] || '')}`.split(',').map( item => {
                return(
                  <React.Fragment key={`budget_invested_in_your_project_${item}`}>
                    <br/>
                    <span class='small'>{`${getDescriptionFrom(item, props.budget_invested)}`}</span>
                  </React.Fragment>
                )
              })
            }
          </FieldTitle>
        </>
      }

      {
        props.data['digital_transformation_process_or_adoption'] === false &&
        <>
          <FieldTitle>
            <span class='medium'>{'¿Qué les falta para iniciar un proyecto?: '}</span>
            <br/>
            <span class='small'>{props.data['things_required_to_start_a_project']}</span>
          </FieldTitle>
        </>
      }

      <FieldTitle>
        <span class='medium'>{'13.- Pide un deseo. Imaginate que tienes el poder de transformar cualquier cosa en tu cadena de abastecimiento en 90 días, ¿Cuál sería?: '}</span>
        <br/>
        <span class='small'>{props.data['wish_in_supply_chain']}</span>
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'¿Cuánto crees que costaría?: '}</span>

        {
          `${(props.data['budget_required_project'] || '')}`.split(',').map( item => {
            return(
              <React.Fragment key={`budget_required_project_${item}`}>
                <br/>
                <span class='small'>{`${getDescriptionFrom(item, props.budget_invested)}`}</span>
              </React.Fragment>
            )
          })
        }        
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'14.- ¿De qué manera crees que Orbinetwork les beneficiaría a tu empresa, a tus clientes y a tus proveedores?: '}</span>
        <br/>
        <span class='small'>{props.data['benefits_to_your_company_in_orbi']}</span>
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'15.- Además de ti ¿Quién más toma de decisiones estratégicas en tu empresa?: '}</span>
        <br/>
        <span class='small'>{props.data['who_else_involved_in_your_company']}</span>
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'16.- Correo electrónico:'}</span>
        <br/>
        <span class='small'>{props.data['email']}</span>
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'Celular:'}</span>
        <br/>
        <span class='small'>{props.data['mobile_phone']}</span>
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'Medio de contacto:'}</span>

        {
          `${(props.data['contact_type'] || '')}`.split(',').map( item => {
            return(
              <React.Fragment key={`contact_type_${item}`}>
                <br/>
                <span class='small'>{`${getDescriptionFrom(item, props.contact_type)}`}</span>
              </React.Fragment>
            )
          })
        }  
      </FieldTitle>

      <FieldTitle>
        <span class='medium'>{'¿Algún agente comercial o referido te recomendo con nosotros?: '}</span>
        <span class='small'>{props.data['is_someone_attending'] ? 'Si' : 'No'}</span>
      </FieldTitle>

      {
        props.data['is_someone_attending'] &&

        <FieldTitle>
          <span class='medium'>{'Agente comercial:'}</span>
          <br/>
          <span class='small'>{props.data['sales_agent_name']}</span>
        </FieldTitle>
      }

    </div>
  );

}