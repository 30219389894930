import React from "react";
import { HistoryContainer } from "./style";
import Loader from "../../../shared/icons/tat/loader";

class PaymentsHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { movements, total, details } = this.props.data;
    return (
      <HistoryContainer>
        <div className="title">
          <div>Historial de compras</div>
          <div>{movements} movimientos</div>
        </div>
        <div className="details">
          {details.map((item) => {
            return (
              <div className="row" key={`history-row-${item.id}`}>
                <div className="section" >{`${item.order}`}</div>
                <div className="section">{`${item.date}`}</div>
                <div className="section">{`${item.status}`}</div>
                <div className="section">{`${item.transaction}`}</div>

                <div className="section-2">
                {
                  (this.props.isLoadingCancelling && item.order_status === 1) ?
                  <Loader width='15px' height='15px'/>
                  :
                  item.order_status === 1 ?
                  <img 
                    alt="" 
                    src="/images/delete_icon.png"
                    onClick={() => {
                      if (this.props.cancelMovement)
                        this.props.cancelMovement(item.id)
                    }}
                    style={{cursor: 'pointer', height: 17, width: 15}}/> : <div style={{height: 17, width: 15}}/>
                }
                </div>
              </div>
            );
          })}
        </div>
        <div className="footer">
          <div className="qty">
            Total de compras <span>{total}</span>
          </div>
        </div>
      </HistoryContainer>
    );
  }
}

export default PaymentsHistory;
