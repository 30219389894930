import React from "react";
import { getEntityItems, readEntities, readEntity } from "../redux/api";
import { connect } from "react-redux";

import { 
  MainContent
} from "./style/view";
import { ResumeTR } from "./client-tr-resume-view";

class NewClientTRDetailsView extends React.Component {

  componentDidMount() {
    this.props.getClient({
      id: this.props.match.params.client_id,
      requiresAuth: false
    })
  }

  render() {
    let isMobile = window.innerWidth < 900;

    return(
      <MainContent
        isSmallSize={isMobile}
        className="register-new-client-co"
      >

        <img alt="" className="logo" src="/images/logo-white.svg" />

        {
          this.props.client &&
          <ResumeTR
            data={this.props.client}
            industry_type_catalog={this.props.industry_type_catalog}
            type_of_service_tr={this.props.type_of_service_tr}
            type_of_operations_tr={this.props.type_of_operations_tr}
            average_number_of_customers={this.props.average_number_of_customers}
            faculties_in_organization={this.props.faculties_in_organization}
            challenges_concerns={this.props.challenges_concerns}
            digital_transformation={this.props.digital_transformation}
            time_in_process={this.props.time_in_process}
            budget_invested={this.props.budget_invested}
            contact_type={this.props.contact_type}
          />
        }
      </MainContent>
    );
  }

}

const mapStateToProps = (state) => {

  let contact_type = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.CONTACTTYPE");
  let industry_type_catalog = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.INDUSTRYTYPECATALOG");
  let faculties_in_organization = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.FACULTIESINORGANIZATION");
  let challenges_concerns = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.CHALLENGESCONCERNSTR");
  let digital_transformation = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.DIGITALTRANSFORMATION");
  let time_in_process = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.TIMEINPROCESS");
  let budget_invested = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.BUDGETINVESTED");
  let isLoading_agent = state?.api["COMPANIES.NEWCLIENTCO.CATALOG.SALESAGENT"]?.status?.isFetching || false;
  let agents = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.SALESAGENT");
  let average_number_of_customers = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.AVERAGENUMBEROFCUSTOMERS");
  let type_of_operations_tr = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.TYPEOFOPERATIONSTR");
  let type_of_service_tr = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.TYPEOFSERVICETR");
  let client = getEntityItems(state, "COMPANIES.NEWCLIENTTR.DETAILS");

  return{
    contact_type,
    agents,
    isLoading_agent,
    budget_invested,
    time_in_process,
    digital_transformation,
    challenges_concerns,
    faculties_in_organization,
    industry_type_catalog,
    average_number_of_customers,
    type_of_operations_tr,
    type_of_service_tr,
    client
  };
}

const mapDispatchToProps = (dispatch) => {

  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.CONTACTTYPE", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.TIMEINPROCESS", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.INDUSTRYTYPECATALOG", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.FACULTIESINORGANIZATION", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.CHALLENGESCONCERNSTR", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.DIGITALTRANSFORMATION", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.BUDGETINVESTED", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.AVERAGENUMBEROFCUSTOMERS", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.TYPEOFOPERATIONSTR", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.TYPEOFSERVICETR", {requiresAuth: false}));

  return {
    getClient: (params, opt) => dispatch(readEntity("COMPANIES.NEWCLIENTTR.DETAILS", params, opt)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewClientTRDetailsView);