import React from "react";
import { getEntityItems, readEntities, readEntity } from "../redux/api";
import { connect } from "react-redux";

import { 
  MainContent
} from "./style/view";
import { ResumeCO } from "./client-co-resume-view";

class NewClientCODetailsView extends React.Component {

  componentDidMount() {
    this.props.getClient({
      id: this.props.match.params.client_id,
      requiresAuth: false
    })
    console.log(this.props.match.params.client_id);
  }

  render() {
    let isMobile = window.innerWidth < 900;

    return(
      <MainContent
        isSmallSize={isMobile}
        className="register-new-client-co"
      >

        <img alt="" className="logo" src="/images/logo-white.svg" />

        {
          this.props.client &&
          <ResumeCO
            data={this.props.client}
            industry_type_catalog={this.props.industry_type_catalog}
            primary_distribution_t1={this.props.primary_distribution_t1}
            secundary_distribution_t2={this.props.secundary_distribution_t2}
            last_mile={this.props.last_mile}
            faculties_in_organization={this.props.faculties_in_organization}
            challenges_concerns={this.props.challenges_concerns}
            digital_transformation={this.props.digital_transformation}
            time_in_process={this.props.time_in_process}
            budget_invested={this.props.budget_invested}
            contact_type={this.props.contact_type}
          />
        }
      </MainContent>
    );
  }

}

const mapStateToProps = (state) => {

  let isLoading = state?.api["COMPANIES.NEWCLIENTCO"]?.status?.isFetching || false;
  let contact_type = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.CONTACTTYPE");
  let industry_type_catalog = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.INDUSTRYTYPECATALOG");
  let primary_distribution_t1 = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.PRIMARYDISTRIBUTIONT1");
  let secundary_distribution_t2 = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.SECUNDARYDISTRIBUTIONT2");
  let last_mile = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.LASTMILE");
  let challenges_concerns = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.CHALLENGESCONCERNS");
  let digital_transformation = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.DIGITALTRANSFORMATION");
  let time_in_process = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.TIMEINPROCESS");
  let budget_invested = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.BUDGETINVESTED");
  let faculties_in_organization = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.FACULTIESINORGANIZATION");
  let agents = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.SALESAGENT");
  let client = getEntityItems(state, "COMPANIES.NEWCLIENTCO.DETAILS");

  return{
    contact_type,
    industry_type_catalog,
    primary_distribution_t1,
    secundary_distribution_t2,
    last_mile,
    challenges_concerns,
    digital_transformation,
    time_in_process,
    budget_invested,
    faculties_in_organization,
    isLoading,
    agents,
    client
  };
}

const mapDispatchToProps = (dispatch) => {

  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.CONTACTTYPE", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.INDUSTRYTYPECATALOG", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.PRIMARYDISTRIBUTIONT1", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.SECUNDARYDISTRIBUTIONT2", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.LASTMILE", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.CHALLENGESCONCERNS", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.DIGITALTRANSFORMATION", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.TIMEINPROCESS", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.BUDGETINVESTED", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.FACULTIESINORGANIZATION", {requiresAuth: false}));

  return {
    getClient: (params, opt) => dispatch(readEntity("COMPANIES.NEWCLIENTCO.DETAILS", params, opt)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewClientCODetailsView);