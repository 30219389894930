import React from "react";
import { withRouter } from "react-router-dom";
import { View, ViewTitle } from '../../../shared/styled/view';
import styled from "styled-components";
import SubscriptionShoppingCarView from '../subscription/subscription-shopping-car-view';
import BillingDataView from "./billing-data-view";
import ConfirmDialog from "../../../shared/dialog/confirm-dialog";
import { isArray } from 'lodash';
import { connect } from "react-redux";
import {
  readEntities,
  getEntityItems,
  readEntity,
  partialEntity,
  createEntity
} from "../../../redux/api/";
import GenericDialog from "../../../shared/dialog/generic-modal";
import SelectInput from "../../../shared/controls/select-input";
import { validateEmails } from "../../../shared/utils";

class BuyOrbibucksView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false,

      itemsToBuy: props.history.location.state.data || [],
      iva: props.history.location.state.iva,
      billingActivated: '2',
      billUse: 0,
      paymentMethod: '15',

      info: {
        rfc: '',
        razon_social: '',
        postal_code: '',
        regimen: 0,
        email_billing: ''
      },

      rfc: '',
      razon_social: '',
      postal_code: '',
      regimen: 0,
      email_billing: '',

      buySuccess: false,
      errorMsg: ''
    };
  }

  componentDidMount = () => {
    this.props.loadFormaDePago();
    this.props.loadUsoCfdi();
    this.props.loadTaxRegime();
    
    this.props.getInvoiceData({
      company_id: this.props.companyId,
    }, {
      alias: "get_invoice_data",
      onSuccess: (response) => {
        this.setInvoiceData(response);
      }
    });
    this.props.loadWalletInfo({
      company_id: this.props.companyId
    });
  }

  setInvoiceData = (response) => {
    try {
      const { data } = response;

      this.state.billUse = data.bill_use;

      this.state.rfc = data.rfc;
      this.state.razon_social = data.razon_social;
      this.state.email_billing = data.email;
      this.state.postal_code = data.postal_code;
      this.state.regimen = data.regimen_fiscal;

      this.state.info.rfc = data.rfc;
      this.state.info.razon_social = data.razon_social;
      this.state.info.email_billing = data.email;
      this.state.info.postal_code = data.postal_code;
      this.state.info.regimen = data.regimen_fiscal;

      this.setState({});
    } catch(error) {
      console.log('Error en acceder a la info de invoice_data', error);
    }
  }

  changeEditable = (items) => {
    this.setState({
      itemsToBuy: items
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    if (value !== null && value !== undefined && !isNaN(value)) {

      this.setState({ [name]: value});
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value});
  }

  buildJsonToSend = () => {
    return({
      company_id: this.props.companyId,
      rfc: this.state.rfc,
      razon_social: this.state.razon_social,
      postal_code: this.state.postal_code,
      email: validateEmails(this.state.email_billing),
      regimen_fiscal: this.state.regimen,
      bill_use: this.state.billUse,
      payment_method: this.state.paymentMethod
    });
  }

  updateInvoiceData = () => {
    this.props.updateInvoiceData(
      this.buildJsonToSend(), 
      {
        onSuccess: (response) => {
          this.state.showEditModal = false;
          this.setInvoiceData(response);
        }
      });
  }

  getInfoToShow = () => {
    let info = this.state.info;

    let tax_regime = this.props.taxRegimes.filter( item => `${item.id}` === `${info.regimen}` )

    if (tax_regime && tax_regime.length > 0) {
      info.regimen_fiscal = tax_regime[0].description;
    } else {
      info.regimen_fiscal = '';
    }

    return info;
  }

  getErrorsToBuy = () => {
    let errors = "";

    if (this.state.billingActivated === '1') {
      errors += String(this.state.billUse) === '0' ? "<b>Seleccione el uso de la factura<b/><br/>" : '';

      errors += String(this.state.rfc).length === 0 ? "<b>Ingresa el rfc<b/><br/>" : '';
      errors += String(this.state.razon_social).length === 0 ? "<b>Ingresa la razón social<b/><br/>" : '';
      errors += String(this.state.postal_code).length === 0 ? "<b>Ingresa el código postal<b/><br/>" : '';
      errors += String(this.state.regimen) === '0' ? "<b>Seleccione el regimen fiscal<b/><br/>" : '';
      errors += String(this.state.email_billing).length === 0 ? "<b>Ingresa algun correo electrónico<b/><br/>" : '';
    }

    if (errors !== "") {
      errors = `Por favor completa los siguientes campos:<br/>${errors}`
    }

    return errors;
  }

  render() {

    return(
      <View>
        <ViewTitle>
          <div style={{flex: "1"}}>
            Realizar compra
          </div>
        </ViewTitle>

        <ContainerView>

          <BillingDataView
            company={this.props.company}
            info={this.getInfoToShow()}
            editAction={() => { this.setState({showEditModal: true}) }}
            billingActivated={this.state.billingActivated}
            onChange={this.handleChange}
            formaDePago={this.props.formaDePago}
            usoCfdi={this.props.usoCfdi}
            billUse={this.state.billUse}
            paymentMethod={this.state.paymentMethod}
            clabe={this.props.walletInfo?.clabe || ''}
          />

          <SubscriptionShoppingCarView
            iva={this.state.iva}
            itemsToBuy={this.state.itemsToBuy}
            deleteItem={this.changeEditable}
            editable={true}
            errors={this.getErrorsToBuy()}
            buyButtonDisabled={this.state.billingActivated === '1' && this.state.billUse === 0}
            isLoading={this.props.isLoadingbuyingOrbibucks}
            buyAction={(info) => {
              info.company_id = this.props.companyId;

              this.props.buyOrbibucks(info, {
                onSuccess: (result) => {
                  this.setState({buySuccess: true})
                },
                onError: (e) => {
                  this.setState({
                    errorMsg: e.response?.data?.error || "Ocurrió un error con el registró del CFDI, verifica tu información"
                  });
                }
              });
              
            }}
            changeEditable={this.changeEditable}
          />

        </ContainerView>

        <ConfirmDialog
          open={this.state.showEditModal}
          isAcceptLoading={this.props.isLoadingInvoiceData}
          title="Datos de facturación"
          class='no-bottom-margin-2'
          contentObject={
            <ModalContainerView>
              <ModalNameFieldView>
                <ModalFieldView>{'RFC:'}</ModalFieldView>
                <ModalFieldView>{'Razón social:'}</ModalFieldView>
                <ModalFieldView>{'Código Postal:'}</ModalFieldView>
                <ModalFieldView>{'Regimen fiscal:'}</ModalFieldView>
                <ModalFieldView>{'Correos electronicos:'}</ModalFieldView>
              </ModalNameFieldView>

              <ModalInputFieldView>

                <ModalFieldView>
                  <TextInput
                    name="rfc"
                    type="text"
                    value={this.state.rfc}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                  <TextInput
                    name="razon_social"
                    type="text"
                    value={this.state.razon_social}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                  <TextInput
                    name="postal_code"
                    type="text"
                    value={this.state.postal_code}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                <SelectInput
                    label='Régimen Fiscal:'
                    value={parseInt(this.state.regimen)}
                    name='regimen'
                    items={this.props.taxRegimes}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                  <TextInput
                    name="email_billing"
                    type="text"
                    value={this.state.email_billing}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

              </ModalInputFieldView>
            </ModalContainerView>
          }
          acceptText="Guardar"
          showCancel={true}
          cancelText="Cerrar"
          cancelButtonType="secondary"
          closeAction={() => {

            this.state.rfc = this.state.info.rfc;
            this.state.razon_social = this.state.info.razon_social;
            this.state.email_billing = this.state.info.email_billing;
            this.state.postal_code = this.state.info.postal_code;
            this.state.regimen = this.state.info.regimen;

            this.setState({showEditModal: false })
          }}
          acceptAction={() => {
            this.updateInvoiceData();
          }}
        />

        <GenericDialog
          open={this.state.buySuccess}
          title={'Orbibucks'}
          message={'Se realizó con éxito la compra'}
          acceptAction={() => { this.props.history.go(-2); }}
          closeAction={() => { this.props.history.go(-2); }}
        />

        <GenericDialog
          open={this.state.errorMsg !== ''}
          title={'Error'}
          message={this.state.errorMsg}
          acceptAction={() => { this.setState({errorMsg: ''}) }}
          closeAction={() => { this.setState({errorMsg: ''}) }}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const formaDePago = getEntityItems(state, "COMPANIES.SAT.CFDI.FORMADEPAGO");
  const usoCfdi = getEntityItems(state, "COMPANIES.SAT.CFDI.USOCFDI");
  const taxRegimes = getEntityItems(state, "COMPANIES.SAT.CATALOGS.REGIMENFISCAL").map(p => ({id: p.id, description: p.clave.concat('-', p.descripcion)}));
  const isLoadingInvoiceData = state?.api['COMPANIES.INVOICEDATA']?.status?.isFetching || false;
  const walletInfo = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.WALLET"); 
  const isLoadingbuyingOrbibucks = state?.api['COMPANIES.WALLETMOVEMENTS']?.status?.isFetching || false;

  return{
    companyId: state.globalCatalog.session.company.id,
    company: state.globalCatalog.session.company,
    formaDePago: (isArray(formaDePago) ? formaDePago : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`})),
    usoCfdi: (isArray(usoCfdi) ? usoCfdi : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`})),
    taxRegimes,
    isLoadingInvoiceData,
    walletInfo: walletInfo && walletInfo.length > 0 ? walletInfo[0] : null,
    isLoadingbuyingOrbibucks: isLoadingbuyingOrbibucks
  };
}

const mapDispatchToProps = (dispatch) => {
  return{
    getInvoiceData: (params, opt) => dispatch(readEntity("COMPANIES.INVOICEDATA", params, opt)),
    loadFormaDePago: () => dispatch(readEntities("COMPANIES.SAT.CFDI.FORMADEPAGO", {}, {prefix: 'orbisat.'})),
    loadUsoCfdi: () => dispatch(readEntities("COMPANIES.SAT.CFDI.USOCFDI", {}, {prefix: 'orbisat.'})),
    loadTaxRegime:  () => dispatch(readEntities("COMPANIES.SAT.CATALOGS.REGIMENFISCAL", {}, {prefix: 'orbisat.'})),
    updateInvoiceData: (params, opt) => dispatch(partialEntity("COMPANIES.INVOICEDATA", params, opt)),
    loadWalletInfo: (params, opt) => dispatch(readEntities("COMPANIES.SUSCRIPTIONS.WALLET", params, opt)),
    buyOrbibucks: (params, opt) => dispatch(createEntity("COMPANIES.WALLETMOVEMENTS", params, opt))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyOrbibucksView));

////////// style

const ContainerView = styled.div`
  display: flex; 
  justify-content: center;
`;

const ModalContainerView = styled.div`
  display: flex;
`;

const ModalNameFieldView = styled.div`
  width: 30%;
`;

const ModalFieldView = styled.div`
  height: 60px;
  width: auto;
  margin-top: 1px;
  color: #252E47;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const ModalInputFieldView = styled.div`
  width: 70%;
`;

const TextInput = styled.input`
  background: white;
  border: none;
  width: 100%;
  height: 30px;
  color: black;
  font-size: 14px;
  border: 2px solid #AFAFB0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;