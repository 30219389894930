import moment from "moment";
import React from "react";
import { connect } from 'react-redux';
import { getEntityItems, readEntities } from "../../redux/api";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import TableD from "../../shared/component/table-d";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import Loader from "../../shared/icons/tat/loader";
// import PaginatorControlled from "../../shared/controls/paginator-controlled";
import { View, ViewTitle } from "../../shared/styled/view";
import ServiceDetailRow from "./service-detail-row";
import ServiceDetailSearchResultRow from "./service-detail-search-result-row";
import * as XLSX from 'xlsx/xlsx.mjs';
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { removeNavbarAction, setNavbarAction } from '../../redux/reducers/navbar-reducer';
import { cancelMultipleRequests } from "../../redux/api/actions";

class ServicesDetailView extends React.Component {
  state = {
    collapseAll: true,
    limit: 10,
    offset: 1,
    searchStr: [],
    tripChips: [],
    searchDone: false,
    loading: false,
    infiniteScrollLimit: 50,
    infiniteScrollOffset: 1,
    infiniteScrollItems: [],
    search_param: '',
    getTripsCalled: false,
    // doSearch: false
  }

  componentDidMount() {
    this.props.cancelMultipleRequests();
    this.setState({ ...this.props.configuration });
    this.setSearchParam();
    this.loadTransactions();
    this.props.setNavbarAction("services-report-detail", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);

    window.onbeforeunload = function() {
      this.props.cancelMultipleRequests();
      return true;
    }.bind(this);
  }

  componentWillUnmount() {
    this.props.setConfig({ ...this.state });
    this.props.removeNavbarAction("services-report-detail");
    this.props.cancelMultipleRequests();
    window.onbeforeunload = null;
  }

  setSearchParam() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let search_param = urlParams.get('search_param');
    this.setState({search_param});
  }

  loadTransactions = () => {
    this.setState({loading: true});
    this.props.cancelMultipleRequests();
    this.props.loadTransactions({
      company_id: this.props.companyId,
      bridge_name: this.props.match?.params?.serviceId || 0,
      limit: this.state.tripChips.length === 1 ? this.state.infiniteScrollLimit : this.state.limit,
      offset: this.state.offset,
      search: this.state.searchStr,
      search_param: this.state.search_param,
      isMultipleCancels: true
    }, {
      onSuccess: (data) => {
        if(this.state.tripChips.length === 1){
          data = data["data"] || [];
          let rowInfoIndex = Object.entries(data[0] || {});
          this.setState({
            loading: false,
            infiniteScrollItems: rowInfoIndex[0][1] || []
          });
        } else {
          this.setState({
            loading: false,
            getTripsCalled: true
          });
        }
      }
    })
  }

  openDetail = (id, wS) => {
    this.props.setServiceDetailConfig(wS);
    this.props.history.push(`/company/reports/services/transactions/${id}?service=${this.props.match?.params?.serviceId || 0}`)
  }

  handleChange = e => {
    // this.setState({[e.target.name]: e.target.value})
    // this.setState({searchDone: false});
    // debugger;
    if (e) {
      if(e.target.value.length > 100){
        this.setState({ 
          tripChips: [], 
          searchStr: [], 
          offset: 1,
          errorMsg: "Máximo 100 folios"
        });
      } else {
        switch (e.target.name) {
          case "inviteTags":
            let tripChips = e.target.value
              .map((i) => ({
                email: i.email,
                avatar: "hide",
                avatarType: "",
                userId: null,
              }));
            this.setState({ 
              tripChips, 
              searchStr: e.target.value.map(e => e.email), 
              offset: 1,
              errorMsg: "",
              infiniteScrollOffset: 1,
              infiniteScrollItems: []
            }, this.loadTransactions);
            break;
          case "deleteTags":
            this.setState({ 
              tripChips: [].concat(e.target.value), 
              searchStr: e.target.value.map(e => e.email), 
              offset: 1, 
              errorMsg: "",
              infiniteScrollOffset: 1,
              infiniteScrollItems: []
            }, this.loadTransactions);
            break;
          default:
        }
      }
    }
  }

  downloadFile = (id, callback, type, name) => {
    callback(true)
    this.props.loadTransactionInfo({
      company_id: this.props.companyId,
      bridge_name: this.props.match?.params?.serviceId || 0,
      limit: this.state.limit,
      offset: this.state.offset,
      transaction_id: id || 0
    }, {
      onSuccess: (response) => {
        const serviceRows = this.parseServiceRows(response);
        if(type === "json"){
          const jsonData = JSON.stringify(serviceRows);
          const file = new Blob([jsonData], { type: "text/plain" });
          const link = document.createElement("a");
          link.setAttribute("href", URL.createObjectURL(file));
          link.setAttribute("download", name);
          link.click();
          callback(false);
        } else {
          let filename= name + '.xlsx';
          let data = serviceRows || [];
          let ws = XLSX.utils.json_to_sheet(data);
          let wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "data");
          XLSX.writeFile(wb,filename);
          callback(false);
        }
      },
      onError: () => {
        callback(false);
      }
    })
  }

  parseServiceRows = (data) => {
    if(!data.length) return [];
    if(typeof data[0].data === 'object' &&
    !Array.isArray(data[0].data) &&
    data[0].data !== null) return [data[0].data];
    return data[0].data || [];
  }
  scrollCallback = () => {
    this.setState({
      infiniteScrollOffset: this.state.infiniteScrollOffset + 1
    }, () => {
      this.props.loadTransactions({
        company_id: this.props.companyId,
        bridge_name: this.props.match?.params?.serviceId || 0,
        limit: this.state.infiniteScrollLimit,
        offset: this.state.infiniteScrollOffset,
        search: this.state.searchStr
      }, {
        alias: "infiniteTranscactions",
        onSuccess: (data) => {
          data = data["data"] || [];
          let rowInfoIndex = Object.entries(data[0] || {});
          this.setState({
            loading: false,
            infiniteScrollItems: this.state.infiniteScrollItems.concat(rowInfoIndex[0][1] || [])
          });
        }
      })
    })
  }

  parseResult = (rows) => {
    // console.log("ROWS", rows);
    return rows.map(rowInfo => {
      let rowInfoIndex = Object.entries(rowInfo);
      // debugger;
      return <ServiceDetailSearchResultRow 
        key={`transaction-row-${rowInfoIndex[0][0]}`}
        order={rowInfoIndex[0][0]} 
        detail ={this.state.tripChips.length === 1 ? this.state.infiniteScrollItems : rowInfoIndex[0][1]}
        openDetail={this.openDetail}
        downloadFile={this.downloadFile}
        TABLECONFIG={TABLECONFIG}
        isSingle={this.state.tripChips.length === 1}
        resultLength={this.props.resultLength}
        scrollCallback={this.scrollCallback}
      />
    })
    
  }

  render() {
    return(
      <View className="web-service-report-view">
        <ViewTitle>
          <div>
            {this.props.match?.params?.serviceId || '-'}
            <br/>
            {/* <span style={{fontSize: "16px", lineHeight: "16px"}}>
              https://v2.orbinetwork.com/api/v2/bridge/octopus/receiver
            </span> */}
          </div>
          <div>
            <FilterBarCompact 
              searchOnly={true}
              searchStr={this.state.tripChips}
              searchPlaceholder={'Buscar folios (100 max)'}
              searchAction={this.handleChange}
              multiText={true}
              clearMultiText={() => {
                this.setState({ tripChips: [], searchStr: [] }, this.loadTransactions);
              }}
            />
            {
              this.state.errorMsg
              ? <div style={{fontSize: "13px", lineHeight: "normal", color: "#af1d1d"}}>{this.state.errorMsg}</div>
              : ''
            }
          </div>
        </ViewTitle>
        <div className="content" style={{height: "90%", marginTop: "20px"}}>
          {
            this.state.loading 
              ? <div style={{height: "300px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <Loader height="100px" width="100px"/>
                </div>
              : <TableD 
                  tableConfig={{
                    ...TABLECONFIG,
                    // columns: this.state.tripChips.length ? SEARCHCOLUMNS : TABLECONFIG.columns
                  }}
                >
                  {
                    (this.state.tripChips.length)
                      ? this.parseResult(this.props.serviceRows || [])
                      : (this.props.serviceRows || []).map((wS) => (
                          <ServiceDetailRow  
                            key={`transaction-row-${wS._id?.$oid || 0}`}
                            data={{
                              ...wS,
                              date: moment(wS.created).format('DD/MM/YYYY HH:mm:ss (utc Z)'),
                            }}
                            openDetail={() => this.openDetail(wS._id?.$oid || 0, wS)}
                            downloadFile={(callback, type) => {this.downloadFile(wS._id?.$oid || 0, callback, type, wS.file)}}
                          />
                        ))
                  }
                </TableD>
          }
          
          <PaginatorControlled
            itemCount={this.state.tripChips.length || this.props.resultLength || 0}
            limit={this.state.limit}
            offset={this.state.offset}
            onChange={(e) =>
              this.setState({ [e.target.name]: e.target.value }, () =>{
                
                // if (this.state.doSearch === true) {
                  // console.log("SEARCHING-FILTER-PAGINATOR");
                  if (this.state.getTripsCalled) {
                    this.loadTransactions();
                  }
                // }
              })
            }
          />
        </div>
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  let companyId = state.globalCatalog.session.company.id;
  const serviceRows = getEntityItems(state, "COMPANIES.BRIDGE.LOGS")["data"] || [];
  const resultLength = getEntityItems(state, "COMPANIES.BRIDGE.LOGS")["size"] || 0;
  // let serviceItemCount = serviceRows.length;
  const  configuration = state.configuration.ServiceDetailView;
  return {
    companyId,
    // serviceItemCount,
    serviceRows,
    resultLength,
    configuration
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setConfig: (config) => dispatch(setConfig("ServiceDetailView", config)),
    loadTransactions: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.BRIDGE.LOGS", params, opt) 
      );
    },
    loadTransactionInfo: (params, opt) => {
      dispatch(
        readEntities("COMPANIES.BRIDGE.TRANSACTIONS", params, opt) 
      );
    },
    setServiceDetailConfig: (config) => dispatch(setConfig("ServiceDetailConfig", config)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests())
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesDetailView);

const headerItemStyle = {
  color: "#A4B4CE",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  textAlign: "center",
};

const cellStyle = {
  color: "#FFFFFF",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  //   textAlign: "center",
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  textAlign: "center",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden"
};

const TABLECONFIG = {
  rowStyle: {
    backgroundColor: "#3D5074",
    height: "25px",
    // borderBottom: "1px solid #A4B4CE",
    // minHeight: "81px"
  },
  headerStyle: {
    backgroundColor: "#3D5074",
    borderBottom: "2px solid #A4B4CE",
  },
  contentStyle: {
    maxHeight: "calc(100% - 117px)",
    overflowY: "auto",
  },
  tableStyle: {
    // maxHeight: "calc(100% - 65px)",
  },
  columns: [
    {
      text: "Fecha y hora",
      width: "25%",
      headerItemStyle,
      cellStyle: { ...cellStyle, justifyContent: "flex-start" },
    },
    {
      text: "ID de transacción",
      width: "35%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Tarea",
      width: "30%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: ".json",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: ".xlsx",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
  ],
};

const SEARCHCOLUMNS = [
  {
    text: "",
    width: "5%",
    headerItemStyle,
    cellStyle,
  },
  {
    text: "Folio",
    width: "20%",
    headerItemStyle,
    cellStyle,
  },
  {
    text: "",
    width: "75%",
    headerItemStyle,
    cellStyle,
  },
]

const searchDummy = [
  {
      "0014664849": [
          {
              "_id": {
                  "$oid": "624b0eb1d904fae804e2abeb"
              },
              "created": "2022-04-04T00:00:00.000+00:00",
              "transaction": 1023234,
              "file": "insert_response_2022-03-04_00_11_17.465306"
          },
          {
              "_id": {
                  "$oid": "624b12d7d904fae804e2abee"
              },
              "created": "2022-04-04T00:00:00.000+00:00",
              "transaction": 1023236,
              "file": "insert_request_2022-03-04_00_19_00.863227"
          }
      ]
  },
  {
      "0014668697": [
          {
              "_id": {
                  "$oid": "624b0eb1d904fae804e2abeb"
              },
              "created": "2022-04-04T00:00:00.000+00:00",
              "transaction": 1023234,
              "file": "insert_response_2022-03-04_00_11_17.465306"
          },
          {
              "_id": {
                  "$oid": "624b12d7d904fae804e2abee"
              },
              "created": "2022-04-04T00:00:00.000+00:00",
              "transaction": 1023236,
              "file": "insert_request_2022-03-04_00_19_00.863227"
          }
      ]
  },
  {
      "67469841515": []
  }
]