import React from "react";
import { Container, MainHeader, BuyContainer } from "./style";
import PaymentsBuyRow from "./buy/buy-row";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getEntityItems, readEntities, readEntity } from "../../redux/api";

class PaymentsBuy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iva: null
    };
  }

  componentDidMount() {
    this.props.loadPackages({
      company_id: this.props.companyId
    });
    this.props.getCompany({
      company_id: this.props.companyId
    },{
      onSuccess: (r) => {
        this.setState({iva: parseFloat( (r && r.country && r.country.vat) ? r.country.vat : '0') });
      }
    });
  }

  getRows = () => {
    return [
      {
        id: 1,
        title: "100",
        qty: 100,
        price: 1.45,
        editable: false,
        iva: 10
      },
      {
        id: 2,
        title: "200",
        qty: 200,
        price: 1.30,
        editable: false,
        iva: 10
      },
      {
        id: 3,
        title: "500",
        qty: 500,
        price: 1.15,
        editable: false,
        iva: 10
      },
      {
        id: 4,
        title: "+1000",
        qty: 1000,
        price: 0.99,
        editable: true,
        iva: 10
      },
    ];
  };

  changeEditable = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validEditable = (e) => {
    if (parseInt(e.target.value) < 1000) {
      this.setState({ [e.target.name]: 1000 });
    }
  };

  render() {
    return (
      <Container>
        <MainHeader>
          <div className="full">
            <div className="title">Comprar Orbibucks</div>
          </div>
        </MainHeader>
        <BuyContainer>
          <PaymentsBuyRow
            data={this.props.packages}
            iva={this.state.iva}
            changeEditable={this.changeEditable}
            validEditable={this.validEditable}
            payAction={ (data) => {
              this.props.history.push('/company/payments/buy/shopping', { data: [data], iva: this.state.iva });
            }}
          />
        </BuyContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {

  const companyId = state.globalCatalog.session.company.id;
  const packages = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.ORBIBUCKSCATALOG") || [];

  return {
    companyId,
    packages
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompany: (params, opt) => dispatch(readEntity("COMPANIES.RETRIEVE", params, opt)),
    loadPackages: (params, opt) => dispatch(readEntities("COMPANIES.SUSCRIPTIONS.ORBIBUCKSCATALOG", params, opt))
  }
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsBuy));
