import React from "react";
import Menu from "../menu/menu";
import Navbar from "../component/navbar";
// import Footer from "./Footer";
import { withRouter } from "react-router-dom";

function Layout(props) {
  if(!props.history.location.pathname.includes("tat2")){
    let zendeskApiScript = Array.from(
      document.getElementsByTagName("script")
    ).find((script) =>
      script.src.includes("https://static.zdassets.com/ekr/snippet.js")
    );

    if (!zendeskApiScript) {
      zendeskApiScript = document.createElement("script");
      zendeskApiScript.src = `https://static.zdassets.com/ekr/snippet.js?key=647661a1-92a6-4a50-ae35-c1d1f37b1cfb`;
      zendeskApiScript.id = `ze-snippet`;
      window.document.head.appendChild(zendeskApiScript);
      
    }
  }
  return (
    <React.Fragment>
      {!props.history.location.pathname.includes("terms") &&
        !props.history.location.pathname.includes("login") &&
        !props.history.location.pathname.includes("tat2") &&
        !props.history.location.pathname.includes("company/select") &&
        !props.history.location.pathname.includes("facility/select") && 
        !props.history.location.pathname.includes("/registerTr") &&
        !props.history.location.pathname.includes("/registerCo") && (
          <Menu
            inRegister={
              props.history.location.pathname.includes("register/")
                ? true
                : false
            }
          />
        )}
      <div 
        className="content" id="pageContent"
        onScroll={e => {
          const event = new CustomEvent('page-content-scroll', { detail: e });
          document.dispatchEvent(event);
        }}
      >
        {props.history.location.pathname.includes("terms") ||
        props.history.location.pathname.includes("login") ||
        props.history.location.pathname.includes("configurations") ||
        props.history.location.pathname.includes("command") ||
        props.history.location.pathname.includes("tat2") ||
        props.history.location.pathname.includes("company/select") ||
        props.history.location.pathname.includes("facility/select") ||
        props.history.location.pathname.includes("register/") ||
        props.history.location.pathname.includes("home") ||
        props.history.location.pathname.includes("facility/create") ||
        props.history.location.pathname.includes("/registerTr") ||
        props.history.location.pathname.includes("/registerCo") ? (
          <React.Fragment />
        ) : (
          <Navbar />
        )}
        {props.children}
      </div>
    </React.Fragment>
  );
}

export default withRouter(Layout);
